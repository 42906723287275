.opportunities {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .opportunities-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    justify-content: space-between;
    position: relative;

    .opportunities-hamburger {
      width: 30px;
      height: 30px;
      margin-right: 1.25rem;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }

    .opportunities-icon {
      width: 10%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: #18191d;
      img {
        width: 80%;
        height: 80%;
      }
    }

    .search-opportunities {
      width: 43%;
      height: calc(100% - 2rem);
      border: 0.5px solid #e7e7e7;
      background: white;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        opacity: 0.25;
        padding-right: 20px;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #18191d;
          font-weight: 700;
          background: transparent;
        }
      }
    }

    .w-btn {
      padding: 0.65rem 1.75rem;
      color: #ffffff;
      background: #18191d;
      font-size: 0.75rem;
      font-weight: 520;
      border-radius: 25px;
      cursor: pointer;
      transition: all ease 0.2s;
      height: 100%;
      display: flex;
      align-items: center;
      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .w-space {
    width: 100%;
    height: 5.5%;
    padding: 0 3rem;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option-selected {
        background: rgba(229, 229, 229, 0.25);
        font-weight: 600;
        padding: 5px 1.75rem;
        border-radius: 25px;
      }
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          color: #18191d;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .hamburger {
        color: #18191d;
        font-weight: 700;
        display: flex;
        p {
          margin-bottom: 0;
        }
        .hov-animate {
          transition: all ease 0.3s;
          &:hover {
            scale: 1.08;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .opportunities-content {
    width: 100%;
    height: calc(100% - 13.5%);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .topArea {
      position: relative;
      display: flex;
      background: #222128;
      width: 100%;
      height: 26rem;
      padding: 3rem;
      flex-direction: column;

      .txtt {
        color: #ffffff;
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 3rem;
      }

      .imgBox {
        width: 100%;
        display: flex;
        gap: 2rem;
        margin-bottom: 3rem;
        overflow-x: scroll;

        .imgDiv {
          min-width: 18rem;
          min-height: 10rem;
          position: relative;
          cursor: pointer;
          transition: all ease 0.2s;
          // border: 1px solid red;
          background: white;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          justify-content: center;
          align-items: center;

          img {
            // width: 35%;
            height: 35%;
            background: white;
            z-index: 1;
            border-radius: 15px;
          }

          .background-tint {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            background: rgba(0, 0, 0, 0.6);
            margin: auto;
            z-index: 2;
          }

          .imgTxt {
            width: 100%;
            font-size: 1.2rem;
            font-weight: 500;
            display: flex;
            justify-content: center;
          }

          .imgDots {
            position: absolute;
            color: #ffffff;
            left: 92%;
            top: 6%;
            z-index: 3;

            img {
              width: 5px;
            }
          }

          &:hover {
            // transform: scale(1.02);
            border: 1px solid #222128;
          }
        }
      }

      .bottomArea {
        border: 0.5px solid #e7e7e7;
        position: absolute;
        background: #ffffff;
        display: flex;
        top: 89%;
        left: 3rem;
        right: 3rem;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;

        .bottomArea-content {
          width: calc(100% / 3);
          display: flex;
          flex-direction: column;
          padding: 2rem 1rem;
          border-right: 0.5px solid #e7e7e7;
          position: relative;

          & > :nth-child(1) {
            font-weight: 600;
          }

          .sideBorder {
            position: absolute;
            width: 6px;
            height: 20px;
            background: #cf1a38;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            left: 0;
            top: 33px;
          }

          .emptyDiv {
            background: rgba(237, 237, 237, 0.37);
            width: 100%;
            height: 2.5rem;
            margin-top: 1rem;
            border-radius: 5px;
          }

          .each-Content {
            width: 100%;
            border: 0.5px solid #e7e7e7;
            border-radius: 15px;
            display: flex;
            gap: 15px;
            margin-top: 1rem;
            align-items: center;
            overflow: hidden;
            padding: 1.25rem;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              transform: scale(1.05);
            }

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }

            .appName {
              font-size: 1.2rem;
              font-weight: 600;
            }

            .appDesc {
              font-size: 0.9rem;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .opportunities-content1 {
    width: 100%;
    height: calc(100% - 13.5%);
    display: flex;
    flex-direction: column;
    padding: 3rem;
    background: rgba(237, 237, 237, 0.25);

    .mapArea {
      width: 100%;
      background: #ffffff;
      border-radius: 25px;
      // height: 35rem;

      .coverPic-section {
        width: 100%;
        height: 10rem;
        border-bottom: 0.5px solid #e7e7e7;
        z-index: 1;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;

        img {
          width: 100%;
          height: 100%;
          border-top-right-radius: 25px;
          border-top-left-radius: 25px;
        }
      }

      .bankerData {
        width: 100%;
        display: flex;
        padding: 1rem 2rem;
        gap: 1.5rem;
        align-items: center;

        .bankerIcon {
          width: 7rem;
          height: 7rem;
          border: 0.5px solid #e7e7e7;
          border-radius: 10px;
          margin-top: -3rem;
          z-index: 2;
          background: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 75px;
            height: 75px;
          }
        }

        .bakerNameDiv {
          width: calc(100% - 8.5rem);
          // border: 1px solid red;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .promoteBtn {
            color: #ffffff;
            background: #cf1a38;
            font-size: 1rem;
            font-weight: 600;
            padding: 0.5rem 2rem;
            border-radius: 35px;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }

      .bioDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem 2rem 2.5rem;
        // height: 7rem;

        & > :nth-child(1) {
          font-weight: 500;
        }

        & > :nth-child(2) {
          font-size: 0.9rem;
          font-weight: 100;
        }
      }

      .statisticsDiv {
        width: calc(100% - 4rem);
        margin: auto;
        border-top: 1px solid #e7e7e7;
        padding: 2.5rem 0;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .headerrDiv {
          width: 100%;
          display: flex;

          div {
            font-weight: 500;
            width: 20%;
            display: flex;
          }
        }

        .statisticsData {
          width: 100%;
          display: flex;

          div {
            font-size: 1.2rem;
            width: 20%;
            font-weight: 100;
            display: flex;
          }
        }
      }
    }
  }

  .promoteModal {
    position: fixed;
    width: 25%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 5;
    background: #ffffff;
    transition: all ease 0.3s;
    border-radius: 25px;
    padding: 3rem;
    display: flex;
    flex-direction: column;

    .headerrr {
      width: 100%;
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 3rem;
      display: flex;
      text-align: center;
    }

    .footerrr {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      div {
        width: 100%;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 700;
        cursor: pointer;
        transition: all ease 0.3s;
        padding: 1rem 0;
        border-radius: 35px;

        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }

  .popularO {
    position: fixed;
    height: calc(100% - 13.5%);
    top: 13.5%;
    border-left: 0.5px solid #e5e5e5;
    // border-top: 0.5px solid #e5e5e5;
    width: 30%;
    max-width: 550px;
    background: white;
    z-index: 3;
    padding: 1rem 3rem 2rem;
    transition: all ease-in 0.4s;
    right: 0;

    .head-txt {
      font-size: 1.75rem;
      font-weight: 550;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 6rem;

      .close-div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.07);
        }
      }
    }

    .each-action {
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      cursor: pointer;
      padding: 2rem 1.5rem;
      margin-top: 1rem;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.07);
      }

      div {
        font-weight: 500;
      }
    }

    .overall-div {
      width: 100%;
      height: calc(100% - 11rem);
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .upload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 2rem 0rem;
        margin-top: 1rem;

        .imgUpload {
          border: 0.5px solid #e7e7e7;
          padding: 1rem;
          border-radius: 5px;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .subbTxt {
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: 1rem;
      }

      .each-action1 {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        padding: 2rem 1.5rem;
        margin-top: 1rem;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }

        .partition {
          width: 100%;
          height: 100%;
          display: flex;

          div {
            width: 30%;
            height: 100%;
          }

          input {
            width: 70%;
            height: 100%;
            border: none;
            background: transparent;
            text-align: end;
            &::placeholder {
              font-weight: 550;
            }
          }
        }

        div {
          font-weight: 500;
        }

        img {
          width: 25px;
          height: 25px;
        }

        input {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          &::placeholder {
            font-weight: 550;
          }
        }
      }

      .each-action2 {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        margin-top: 1rem;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }

        input {
          width: 80%;
          height: 100%;
          border: none;
          padding: 2rem 1.5rem;
          background: transparent;
          &::placeholder {
            font-weight: 550;
          }
        }

        .currencyDiv {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 0.5px solid #e5e5e5;
          font-weight: 700;
        }
      }

      .successMsg {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
      }
    }

    .stepBtns {
      width: 100%;
      height: 5rem;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;

      div {
        width: 49%;
        border-radius: 35px;
        color: #ffffff;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
