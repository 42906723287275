.subscriptionsPage {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .subscriptions-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    justify-content: space-between;
    position: relative;

    .subscriptions-hamburger {
      width: 30px;
      height: 30px;
      margin-right: 1.25rem;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }

    .subscriptions-icon {
      width: 10%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: #18191d;
      img {
        width: 80%;
        height: 80%;
      }
    }

    .search-subscriptions {
      width: 43%;
      height: calc(100% - 2rem);
      border: 0.5px solid #e7e7e7;
      background: white;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        opacity: 0.25;
        padding-right: 20px;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #18191d;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #18191d;
          font-weight: 700;
          background: transparent;
        }
      }
    }

    .w-btn {
      padding: 0.65rem 1.75rem;
      color: #ffffff;
      background: #18191d;
      font-size: 0.75rem;
      font-weight: 520;
      border-radius: 25px;
      cursor: pointer;
      transition: all ease 0.2s;
      height: 100%;
      display: flex;
      align-items: center;
      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .w-space {
    width: 100%;
    height: 5.5%;
    padding: 0 3rem;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option-selected {
        background: rgba(229, 229, 229, 0.25);
        font-weight: 600;
        padding: 5px 1.75rem;
        border-radius: 25px;
      }
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          color: #18191d;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .hamburger {
        color: #18191d;
        font-weight: 700;
        display: flex;
        p {
          margin-bottom: 0;
        }
        .hov-animate {
          transition: all ease 0.3s;
          &:hover {
            scale: 1.08;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .manage-box {
    width: 100%;
    padding: 1rem 3rem;
    border-bottom: 1px solid #e7e7e7;
    height: 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .manage-left-div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .amount-txt {
        font-size: 1.75rem;
        font-weight: 500;
      }
    }

    .manage-btn {
      background: #18191d;
      color: #ffffff;
      font-weight: 500;
      padding: 0.5rem 2rem;
      border-radius: 35px;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .white-box {
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
    height: 4rem;
  }

  .subscriptions-content {
    width: 100%;
    height: calc(100% - 13.5% - 12rem);
    background: rgba(237, 237, 237, 0.37);
    padding: 0.5rem 3rem;
    overflow-y: scroll;

    .app-cards-div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 0 2rem;
      justify-content: flex-start;

      .each-app-card {
        margin-top: 2.5rem;
        background: #ffffff;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        width: calc(calc(100% - 6rem) / 4);
        height: max-content;
        justify-content: center;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

        .applogo-box {
          height: 3rem;
        }

        .app-name-txt {
          margin-top: 1.5rem;
          margin-bottom: 2.5rem;
          font-size: 0.9rem;
          font-weight: 500;
        }

        .cost-txt {
          font-size: 1.25rem;
          font-weight: 600;
        }
      }
    }
  }

  .subscription-drawer {
    position: absolute;
    width: 35%;
    top: 13.5%;
    height: 86.5%;
    border-left: 1px solid #ebebeb;
    background: white;
    z-index: 5;
    transition: all ease 0.3s;

    .drawer-content {
      width: 100%;
      height: 100%;
      position: relative;

      .header-txt {
        width: 100%;
        height: 6rem;
        font-size: 1.5rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2rem;
        gap: 7px;
        padding-bottom: 0.5rem;
      }

      .sub-header-txt {
        width: 100%;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 2.5rem;
      }

      .previous-btn-div {
        width: 100%;
        height: 5rem;
        padding: 0 1.5rem;

        .previous-btn {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem;
          border-radius: 15px;
          border: 1px solid #e7e7e7;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .drawer-mid-area {
        width: 100%;
        height: calc(100% - 24rem);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        padding: 2.5rem 1.5rem;

        .drawer-hiw-div {
          display: flex;
          justify-content: space-between;
          border-radius: 25px;
          border: 1px solid #e7e7e7;
          align-items: center;
          padding: 1.5rem;

          .drawer-hiw-left-div {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .hiw-textt {
              font-size: 1.25rem;
              font-weight: 500;
            }
          }
        }

        .app-boxes {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 2.5rem 3rem;
          justify-content: space-between;

          .each-app-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
            transition: all 0.2s ease;

            .app-border {
              border-radius: 10px;
              border: 1px solid #e7e7e7;
              width: 75px;
              height: 75px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .app-namee {
              font-size: 0.8rem;
              font-weight: 500;
            }

            &:hover {
              .app-border {
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
              }

              .app-namee {
                transform: scale(1.05);
              }
            }
          }
        }
      }

      .drawer-footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e7e7e7;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .yet-text {
          font-size: 0.9rem;
          font-weight: 500;
          display: flex;
          justify-content: center;
        }

        .start-btn {
          color: #ffffff;
          font-weight: 500;
          border-radius: 35px;
          background: #3296ca;
          padding: 1rem 0;
          text-align: center;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  .subscription-overlay {
    position: absolute;
    inset: 0;
    z-index: 4;
  }
}
