.EaDrawer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 35%;
  height: 100%;
  z-index: 5;
  background: #ffffff;
  transition: all ease 0.4s;
  top: 0;
  border-left: 0.5px solid #e7e7e7;
  .suceesmsg {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 27px;
    /* or 169% */

    display: flex;
    align-items: center;

    color: #18191d;
  }
  .sectionskeletonStstua {
    width: 4rem;
    height: 1.5rem;
  }
  .hoverEffer {
    &:hover {
      scale: 1.1;
    }
  }
  .borderSectionEadrawer {
    height: 1px;
    width: 100%;
    margin-top: 3rem;
    background: #ddd;
    margin-bottom: 1.5rem;
  }
  .subSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5.5rem;
    padding: 0 1rem;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 10px;
    .ActiveEA {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: right;

      color: #50c7ad;
    }
    .Subscription {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      display: flex;
      align-items: center;

      color: #18191d;
    }
  }
  .labelSubmit {
    width: 88%;
    height: 68px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;

    background: #9fd6df;
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: -9px;
    margin: auto;
    left: 50%;
    /* top: 50%; */
    cursor: pointer;
    transform: translate(-50%, -50%);
    &:hover {
      bottom: -4px;
    }
  }
  .ImageNameSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .scrollerFundsCutom {
    overflow: scroll;
    height: calc(100vh - 141px);
    margin-top: 2rem;
  }
  .scrollerFunds {
    overflow: scroll;
    height: calc(100vh - 48px);
    margin-top: 2rem;
  }
  .innerFundsAdding {
    padding: 0rem 2rem;
  }
  .imgTitle {
    width: 103px;
    justify-content: center;
    margin-top: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #5f6163;
  }
  .upload-options {
    position: relative;
    height: 75px;

    cursor: pointer;
    overflow: hidden;
    text-align: center;
    transition: background-color ease-in-out 150ms;
    width: 103px;
    height: 103px;

    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    &:hover {
    }
    & input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    & .label {
      display: flex;
      align-items: center;
      width: 100%;
      background: white;
      height: 100%;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      &::after {
        // content: 'add';
        // font-family: 'Material Icons';
        // position: absolute;
        // font-size: 2.5rem;
        // color: rgba(230, 230, 230, 1);
        // top: calc(50% - 2.5rem);
        // left: calc(50% - 1.25rem);
        // z-index: 0;
      }
      //   & span {
      //     display: inline-block;
      //     width: 50%;
      //     height: 100%;
      //     text-overflow: ellipsis;
      //     white-space: nowrap;
      //     overflow: hidden;
      //     vertical-align: middle;
      //     text-align: center;
      //     &:hover i.material-icons {
      //       color: lightgray;
      //     }
      //   }
    }
  }
  .chekfund {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #5f6163;
    width: 6rem;
    justify-content: center;
    position: relative;
    /* right: -14px; */
    border-left: 0.5px solid #e7e7e7;
  }
  .headerr {
    width: 100%;
    height: 93%;
    // padding: 3rem 2rem;
    width: 100%;
    height: 100%;
    padding: 3rem 0rem;
    padding-bottom: 0;

    .topp {
      width: 100%;
      padding: 0rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin-bottom: 0;
        color: #18191d;
      }
      .closebttn {
        width: 20%;
        display: flex;
        justify-content: space-between;

        img {
          transition: all ease 0.3s;
          cursor: pointer;

          &:hover {
            scale: 1.1;
          }
        }
      }
    }

    .each-field {
      width: 100%;
      /* height: 10rem; */
      display: flex;
      flex-direction: column;
      padding: 1.25rem 0;
      position: relative;

      .dotdiv {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #d80027;
        right: 5%;
        bottom: 33%;
      }

      .dddiv {
        position: absolute;
        width: 12px;
        height: 12px;
        right: 5%;
        bottom: 37%;
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover {
          scale: 1.08;
        }
      }

      .tbddiv {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 0.5px solid #e7e7e7;
        color: #18191d;
        opacity: 0.25;
        font-weight: 700;
      }

      p {
        font-size: 1.25rem;
        color: #18191d;
        font-weight: 400;

        display: flex;
        align-items: center;

        color: #5f6163;
      }

      .inputt-field {
        width: 100%;
        border: 0.5px solid #e7e7e7;
        height: 5.5rem;
        padding: 0 1rem;
        display: flex;
        border-radius: 10px;
        .placee {
          &::placeholder {
            opacity: 0.25;
          }
        }

        .placeee {
          &::placeholder {
            font-weight: 700;
            opacity: 0.25;
          }
        }

        input {
          cursor: pointer;
          display: flex;
          height: 100%;
          border: none;
          width: 90%;
        }

        .each-appp1 {
          width: 90%;
          height: 100%;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          justify-content: center;

          p {
            margin-bottom: 0;
            color: #18191d;
          }
        }

        .each-appp2 {
          display: flex;
          width: 90%;
          height: 100%;
          gap: 15px;
          align-items: center;

          p {
            margin-bottom: 0;
          }
        }
      }
    }

    .appslist {
      width: 100%;
      height: 70%;
      border: 0.5px solid #e7e7e7;
      overflow-y: scroll;

      .each-appp {
        width: 100%;
        padding: 2rem 3rem;
        border-bottom: 0.5px solid #e7e7e7;
        display: flex;
        gap: 15px;
        align-items: center;
        cursor: pointer;

        p {
          margin-bottom: 0;
          color: #18191d;
        }
      }

      .each-appp1 {
        width: 100%;
        padding: 2rem 3rem;
        border-bottom: 0.5px solid #e7e7e7;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        p {
          margin-bottom: 0;
          color: #18191d;
        }
      }
    }
  }

  .footerr {
    width: 100%;
    height: 4rem;
    border-top: 0.5px solid #e7e7e7;
    display: flex;
    margin-top: 4rem;

    .goback {
      width: 50%;
      border-right: 0.5px solid #e7e7e7;
      color: #18191d;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      font-weight: 650;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        font-size: 1.4rem;
      }
    }

    .addd {
      width: 50%;
      color: #18191d;
      opacity: 0.25;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      font-weight: 650;
      //   cursor: not-allowed;
      background: white;
    }

    .addd1 {
      width: 50%;
      color: #ffffff;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      font-weight: 650;
      background: #18191d;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        font-size: 1.4rem;
      }
    }
  }
}
