.points-dashboard {
  height: calc(100% - 13.5%);
  padding: 2vw;
  display: flex;
  flex-direction: column;
  /* column-gap: 22rem; */
  row-gap: 2vw;
  padding-right: 0;

  .top-left {
    background: linear-gradient(276.23deg, #4b2a91 4.37%, #09a7b3 167.31%);
    border: 0.5px solid #ebebeb;
    border-radius: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70vw;
    padding: 2vw;
    .requrement {
      width: 100%;
      height: 30px;
      justify-content: center;

      border: 1.5px solid #ffffff;
      border-radius: 56px;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
        transition: all 0.3s ease-in-out;
      }
    }
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      line-height: 31px;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 12px;
      color: #ffffff;
      // margin-bottom: 0;
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
      margin-bottom: 0;
    }
    .innerCardTop {
      width: 23%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      height: 177px;
      padding: 1vw;

      border: 1px solid #ebebeb;
      border-radius: 15px;
      &:hover {
        transform: translateY(-5px);
        transition: all 0.3s ease-in-out;
      }
    }
    .topCardInner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    h1 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      display: flex;
      align-items: center;

      color: #ffffff;
      margin-bottom: 0;
      margin-bottom: 0.3vh;
    }
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;

      color: #ffffff;
      margin-bottom: 0;
    }
  }
  .top-inner {
    height: 43%;
    display: flex;
    align-items: center;
    padding-right: 2vw;
    gap: 2vw;
  }
  .pointBottomCards {
    height: 57%;
    display: grid;
    grid-auto-flow: column;
    overflow: scroll;
    width: 100%;
    // gap: 2vw;
    /* margin-right: 2rem; */
    white-space: nowrap;

    .sectionBottomPoits {
      width: 100%;
    }
    .numberCard {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .pointsCards {
      height: calc(100% - 0.6rem);
      width: 20vw;
      padding: 1.5vw;
      align-items: center;
      margin-right: 2vw;

      background: #ffffff;
      border: 0.5px solid #ebebeb;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:hover {
        transform: translateY(-5px);
        transition: all 0.3s ease-in-out;
      }
      .time-points {
        height: 33px;
        justify-content: center;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;
        background: rgba(75, 42, 145, 0.1);
        border-radius: 25px;
        svg {
          margin-right: 10px;
        }
      }
      .analytis {
        height: 51px;
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* or 119% */

        display: flex;
        align-items: center;
        text-align: center;

        color: #ffffff;
        margin-top: 3vh;
        justify-content: center;
        background: #4b2a91;
        border-radius: 25px;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
      }
      .botteMpoinumberCardntDetial {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 2.5vh;
      }
      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        color: #212529;
      }
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: right;
        margin-bottom: 0;
        color: #212529;
      }
      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 50px;
        line-height: 61px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 2.4vh;
        color: #212529;
      }
      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #212529;
        margin-bottom: 0;
      }
    }
  }

  .top-right {
    width: 30vw;
    height: 100%;
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      display: flex;
      align-items: center;

      color: #212529;

      margin-bottom: 0;
    }
    .cashout {
      width: 100%;
      height: 51px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 19px;
      /* or 106% */

      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
      justify-content: center;

      background: #3db69a;
      border-radius: 25px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
    .topheader {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .calculate {
      width: 100%;
      padding-left: 1.4rem;
      height: 58px;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 19px;
      /* or 86% */
      //   margin-bottom: 1rem;

      display: flex;
      align-items: center;

      color: #343c5b6b;

      //   opacity: 0.5;

      background: #f5f5f5;
      border-radius: 10px;
    }
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 19px;
      /* or 173% */
      margin-bottom: 0;
      display: flex;
      align-items: center;
      // margin-top: 2.3rem;
      color: #212529;
    }
    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;

      color: #212529;
      margin-bottom: 0;
      span {
        color: #4b2a91;
        margin-left: 5px;
        font-weight: 600;
      }
    }
    img {
      width: 68px;
      height: 68px;
      margin-right: 15px;
      border-radius: 10px;
    }
    .header {
      background: #ffffff;
      border: 0.5px solid #ebebeb;
      border-radius: 25px;
      height: calc(100% - 0rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2.2vw;
    }
  }
}
